import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import KatbookTabs from '../KatbookTabs';
import UniversalKBTabs from './KeyBenefits/UniversalKBTabs';
import EnquirePopupFrom from '../../../../Components/EnquirePopupFrom';
import TalktoExpert from '../../TalktoExpert';
import BookaDemo from '../../BookaDemo';

const Universalcoverage = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [shadow, setShow] = React.useState(); 
  return (
    <div className="ProInnerPage">
        <section className='InnerPBanSec'>
            <div className='IPBBgimg'>
                <img src={require ('../../../../asstes/images/kampusBanBg.jpg')} alt=""/>
            </div>
            <div className='BanContSec'>
                <Container>
                    <div className='textBlack'>
                        <h1>Universal Coverage</h1>
                    </div>
                </Container>
            </div>
        </section>

        <section className='ProInIntroSec kbook-unicov'>
            <Container>
                <Row>
                    <Col md='8' >
                        <div className='textBlack tbTitle'>
                            <h3 data-aos="fade-down" data-aos-duration="1000">Redefining  <span>Education</span></h3>
                            <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Complete Education Accessibility for All</h4>
                            <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Universal Coverage is a revolutionary feature ensuring a comprehensive syllabus aligned with Government and Board-prescribed content. Transforming education, this innovation delivers a holistic experience for students, teachers, parents, and institutions. From streamlined teaching and immersive learning for educators and students to enhanced visibility and progress tracking for parents, Universal Coverage redefines education. With identified keywords, concept videos, and expert teachings seamlessly integrated, it offers a complete educational package. </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='ProInKeySec'>
            <Container>
                <Row>
                    <Col md='12' >
                        <div className='textBlack tbTitle textCenter titlemBtm'>
                            <h3 data-aos="fade-down" data-aos-duration="1000">Key <span>Benefits</span></h3>
                            <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100"> Transformative Learning Experience that <br/>Redefines Education Accessibility</h4>
                            <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Universal Coverage aligns a comprehensive syllabus that holistically offers streamlined teaching, <br/>immersive learning and enhanced progress tracking.</p>
                        </div>
                        <div class="kbtabBox">
                            <UniversalKBTabs/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='ProInvideoSec'>
            <Container>
                <Row>
                    <Col md='12' >
                        <div className='textBlack tbTitle  textCenter titlemBtm'>
                            <h3 data-aos="fade-down" data-aos-duration="1000">Video Showcase of <br/><span>Transforming Education</span></h3>
                            <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Understand Katbook's Universal Coverage: A game-changer in education, offering a complete <br/>solution for students, educators, parents, and institutions.</p>
                        </div>
                    </Col>
                    <Col md='6' >
                        <div className='videobox' data-aos="fade-right" data-aos-duration="1000">
                            <div className='videoboxImg'>
                                <Link to="#"><img src={require ('../../../../asstes/images/unicovvideoimgOne.jpg')} alt=""/></Link>
                                <h3>Lorem Ipsum <br/>simply Text</h3>
                            </div>
                            <div className='videoboxCOnt'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md='6' >
                        <div className='videobox' data-aos="fade-left" data-aos-duration="1000">
                            <div className='videoboxImg'>
                                <Link to="#"><img src={require ('../../../../asstes/images/unicovvideoimgTwo.jpg')} alt=""/></Link>
                                <h3>Lorem Ipsum <br/>simply Text</h3>
                            </div>
                            <div className='videoboxCOnt'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='WChoKBSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000">Why Choose <span>KATBOOK?</span></h2>
                    <h4 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Unveiling the Benefits</h4>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">Where the stakeholders thrive together through seamless collaboration and<br/> transformative benefits.</p>
                </div>
                <div className='WCHooTab'>
                    <KatbookTabs />
                </div>
            </Container>
        </section>

        <section className='HoldonSec'>
            <Container>
                <div className='textBlack tbTitle textCenter titlemBtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Hold On</span></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">did you try our demo ?</p>
                </div>
                <div className='honInROw'>
                    <Row>
                        <Col md='6' className='honCol honColL'>
                            <div className='HoFromCont'>
                                <h4>Talk To An Expert</h4>
                                <TalktoExpert/>
                            </div>
                        </Col>
                        <Col md='6' className='honCol honColR'> 
                            <div className='HoCalen'>
                                <BookaDemo/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section> 
        <EnquirePopupFrom show={modalShow} onHide={() => setModalShow(false)} /> 

    </div>
  )
}

export default Universalcoverage