import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF,FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";

import './footer.css';
import Enquirenow from './Enquirenow';

const Footer = () => {
  return (
    <>
    <Enquirenow />
    <p class="stickybtn"><Link to={process.env.PUBLIC_URL + '/publishwithus'} >Publish With Us</Link></p>

    <footer >
        <Container>
            <Row>
                <Col>
                    <ul className='footul footMmenu'>
                        <li><Link to={`${process.env.PUBLIC_URL}/`}>HOME</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/about`}>about us</Link></li>
                        <li className='nolink'><Link to="/">News & Insights</Link></li>
                        <li className='nolink'><Link to="/">Testimonials</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/oursolutions'}> Solutions </Link></li>
                        {/* <li><Link to="/">Verticals</Link></li> */}
                        <li><Link to={process.env.PUBLIC_URL + '/contactus'}>Let's Talk</Link></li>
                    </ul>
                </Col>
                <Col>
                    <h4>Our Products</h4>
                    <ul className='footul'>
                        <li><Link to={process.env.PUBLIC_URL + '/katbook'}>Katbook</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/kampus'}>Kampus</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/ksmacc'}>Ksmacc</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/ustudy'}>Ustudy</Link></li>
                    </ul> 
                </Col>
                <Col>
                    <h4>Verticals</h4>
                    <ul className='footul'>
                        <li><Link to={process.env.PUBLIC_URL + '/contactus'}>Schools</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/contactus'}>Tutorials</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/contactus'}>Colleges / Universities</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/contactus'}>Academies</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/contactus'}>Corporates</Link></li>
                    </ul> 
                </Col>
                <Col>
                    {/* <h4>About us</h4>
                    <ul className='footul spcbtmul'>
                        <li><Link to="/">How we work</Link></li>
                        <li><Link to="/">Our values</Link></li>
                        <li><Link to="/">Our history</Link></li>
                    </ul>    */}
                    <h4>Work with us</h4>
                    <ul className='footul'>
                        <li className='nolink'><Link to="/">Careers</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + '/publishwithus'}>Publish with us</Link></li>
                    </ul>   
                </Col>
                <Col className='copyrightcol'>
                    <h4>SOCIAL MEDIA</h4>
                    <ul className='smediaul'>
                        {/* <li><Link to="/"><FaFacebookF /></Link></li> */}
                        {/* <li><Link to="/"><FaTwitter /></Link></li> */}
                        <a href="https://www.instagram.com/ueducate.global/" target='_blank'><li><FaInstagram /></li></a>
                        <a href="https://www.youtube.com/@UEDUCATE-GLOBAL-PVT-LTD" target='_blank'><li><FaYoutube /></li></a>
                        <a href="https://www.linkedin.com/company/ueducate-global-pvt-ltd/posts/?feedView=all" target='_blank'><li><FaLinkedinIn/></li></a>
                    </ul>
                    <p className='coprpara'>Copyright ©<br/> {(new Date().getFullYear())} Ueducate, All<br/> Rights Reserved</p>
                </Col>
            </Row>
        </Container>

    </footer>

    </>
  )
}

export default Footer