import React, { useState, useEffect } from "react";

import { Col,  Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AccordionTab({ title, children, isActive, onClick }) {
  return (
    <div className={`accordion-tab ${isActive ? "active" : ""}`}>
      <div className="accordion-tab-title" onClick={onClick}>
      <h4><span>{title}</span></h4>
      </div>
      <div className="accordion-tab-content">{children}</div>
    </div>
  );
}
function Tab({  content }) {
    return (
      <div className="tab-content">
        <>{content}</>
      </div>
    );
  }
export default function UniversalKBTabs() {
  const [isAccordion, setIsAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const toggleAccordion = () => {
    setIsAccordion(window.innerWidth < 768);
  };

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    toggleAccordion();
    window.addEventListener("resize", toggleAccordion);
    return () => {
      window.removeEventListener("resize", toggleAccordion);
    };
  }, []);

  const tabs = [
    { title: "Immersive learning", content: ( <Row>
        <Col md="5">
            <div className='KBImg'>
                <img src={require ('../../../../../asstes/images/noimages.jpg')} alt="Immersive learning"/>
            </div>
        </Col>
        <Col md="7">
            <div className='KBCont'>
                <h4>Immersive learning</h4>
                <p>The integration of concept videos, along with comprehensive content, makes learning more enjoyable and immersive for students. Clear explanations, insightful narrations, smooth navigation and self-assessment tools foster active engagement, critical thinking, and long-term retention of knowledge.</p>
            </div>
        </Col>
    </Row>), },
    { title: "Streamlined teaching", content: (<Row>
        <Col md="5">
            <div className='KBImg'>
                <img src={require ('../../../../../asstes/images/noimages.jpg')} alt="Streamlined teaching"/>
            </div>
        </Col>
        <Col md="7">
            <div className='KBCont'>
                <h4>Streamlined teaching</h4>
                <p>Katbook's Universal Coverage eliminates the need for educators to search for supplementary materials. With a comprehensive syllabus aligned with the latest Government and Board-prescribed content, teachers can focus on delivering impactful lessons without the hassle of curating additional content.</p>
            </div>
        </Col>
    </Row>), },
    { title: "Holistic Progress Tracking", content: (<Row>
        <Col md="5">
            <div className='KBImg'>
                <img src={require ('../../../../../asstes/images/noimages.jpg')} alt="Holistic Progress Tracking"/>
            </div>
        </Col>
        <Col md="7">
            <div className='KBCont'>
                <h4>Holistic Progress Tracking</h4>
                <p>With self-assessment tools, parents can track their child's progress more comprehensively, allowing for informed discussions with teachers and enabling parents to support their child's education more effectively by understanding the curriculum and learning objectives and better supporting them at home.</p>
            </div>
        </Col>
    </Row>), },
    { title: "Quality enrichment", content: (<Row>
        <Col md="5">
            <div className='KBImg'>
                <img src={require ('../../../../../asstes/images/noimages.jpg')} alt="Quality enrichment"/>
            </div>
        </Col>
        <Col md="7">
            <div className='KBCont'>
                <h4>Quality enrichment</h4>
                <p>Through strategic collaborations with renowned publishers, authors, and content providers, Katbook ensures the quality of existing books is enriched. This collaboration elevates the overall standard of education within institutions.</p>
            </div>
        </Col>
    </Row>), },
    { title: "Efficient resource management", content: (<Row>
        <Col md="5">
            <div className='KBImg'>
                <img src={require ('../../../../../asstes/images/noimages.jpg')} alt="Efficient resource management"/>
            </div>
        </Col>
        <Col md="7">
            <div className='KBCont'>
                <h4>Efficient resource management</h4>
                <p>The Universal Coverage feature contributes to efficient resource management within educational institutions. It minimizes the time and effort spent searching for external resources, allowing institutions to focus on optimizing their educational processes.</p>
            </div>
        </Col>
    </Row>), },
  ];

  return (
    <>
    <div className="responsive-tabs-accordion">
      {isAccordion ? (
        tabs.map((tab, index) => (
          <AccordionTab
            key={index}
            title={tab.title}
            isActive={activeTab === index}
            onClick={() => toggleTab(index)}
          >
            {tab.content}
          </AccordionTab>
        ))
      ) : (
        <>
            <div class="navul">
                <ul className="responsive-tabs">
                {tabs.map((tab, index) => (
                    <li
                    key={index}
                    className={activeTab === index ? "active" : ""}
                    onClick={() => toggleTab(index)}
                    >
                    <h5>{tab.title}</h5>
                    </li>
                ))}
                </ul>  
            </div>
            <div className="tab-content-container">
                {activeTab !== null && (
                <Tab
                    title={tabs[activeTab].title}
                    content={tabs[activeTab].content}
                />
                )}
            </div>         
        </>
      )}
    </div>
        </>
  );
}
