
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './asstes/css/style.css';
import './asstes/fonts/halyardtext_black-webfont.woff';
import './asstes/fonts/halyardtext_black-webfont.woff2';
import './asstes/css/font-style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Components/UEHead/Header";
import Home from "./Pages/Home/Home";
import Footer from "./Components/UEFoot/Footer";
import Aboutus from "./Pages/About/Aboutus";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Katbook from "./Pages/Products/Katbook/Katbook";
import Kampus from "./Pages/Products/Kampus/Kampus";
import Kamacc from "./Pages/Products/Kamacc/Kamacc";
import Contactus from "./Pages/Contactus/Contactus";
import ScrollToTop from "./Components/ScrollToTop";
import OurSolutions from "./Pages/OurSolutions/OurSolutions";
import Ustudy from "./Pages/Products/Ustudy/Ustudy";
import Publishwithus from "./Pages/Products/PublishWithUs/Publishwithus";
import Universalcoverage from "./Pages/Products/Katbook/KatbookInnerPage/Universalcoverage";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
      disable: window.innerWidth < 768,
    });
    AOS.refresh();
  }, []);

  console.log(process.env,"PUBLIC_URL");
  return (
    <div className="App">
        <ScrollToTop/>
          <Header />
          <Routes basename="ueducate" forceRefresh={true}>
             <Route exact index path={process.env.PUBLIC_URL + '/'} element={<Home />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/about'} element={<Aboutus />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/katbook'} element={<Katbook />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/kampus'} element={<Kampus />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/ksmacc'} element={<Kamacc />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/contactus'} element={<Contactus />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/oursolutions'} element={<OurSolutions />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/ustudy'} element={<Ustudy />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/publishwithus'} element={<Publishwithus />}></Route>
             <Route exact path={process.env.PUBLIC_URL + '/universalcoverage'} element={<Universalcoverage />}></Route>
          </Routes>
          <Footer />
    </div>
  );
}

export default App;
